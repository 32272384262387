import { Background } from "./Home.styles";

export default function Home() {
  return (
    <Background>
      <div>
        <img
          src="/coming-soon.png"
          alt=""
          className="image"
        />

        <h1>platfom.com is coming soon</h1>
      </div>
    </Background>
  );
}
