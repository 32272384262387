import styled from "styled-components";

export const Background = styled.div`
  div {
    width: 1000px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 80px 0;
  }

  .image {
    width: 450px;
  }

  h1 {
    text-align: center;
    border-top: 3px solid #747272;
    font-size: 40px;
    margin: 40px 0;
  }

  @media (min-width: 310px) and (max-width: 549px) {
    div {
      width: 300px;
    }

    .image {
      width: 200px;
    }

    h1 {
      font-size: 15px;
    }
  }

  @media (min-width: 550px) and (max-width: 749px) {
    div {
      width: 500px;
    }

    .image {
      width: 250px;
    }

    h1 {
      font-size: 25px;
    }
  }

  @media (min-width: 750px) and (max-width: 1100px) {
    div {
      width: 700px;
    }

    .image {
      width: 350px;
    }

    h1 {
      font-size: 35px;
    }
  }
`;
